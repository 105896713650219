import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render(pageTitle, description) {
    pageTitle = "CONTACT";
    description = "下記フォームより必要事項を入力していただき、お問い合わせください。";
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                siteUrl
              }
            }
          }
        `}
        render={(data) => (
          <Layout>
            <Helmet
              title={pageTitle + " | " + data.site.siteMetadata.title}
              meta={[
                { name: "description", content: `${description}` },
                {
                  property: "og:title",
                  content: `${pageTitle} | ${data.site.siteMetadata.title}`,
                },
                {
                  property: "og:url",
                  content: `${data.site.siteMetadata.siteUrl}/contact`,
                },
                {
                  property: "og:description",
                  content: `${description.substr(0, 85)}`,
                },
              ]}
            />
            <section className='section'>
              <div className='container'>
                <div className='content columns'>
                  <div className='column is-10 is-offset-1'>
                    <h1 className='orbitron heading'>{pageTitle}</h1>
                    <p>{description}</p>
                    <p>
                      <small>
                        ※メールアドレスの入力ミスにお気をつけください。
                        <br />
                        ※項目は全て必須入力です。
                      </small>
                    </p>
                    <form className='mt-5' name='contact' method='post' action='/contact/thanks/' data-netlify='true' data-netlify-honeypot='bot-field' onSubmit={this.handleSubmit}>
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type='hidden' name='form-name' value='contact' />
                      <div hidden>
                        <label>
                          入力されていません: <input name='bot-field' onChange={this.handleChange} />
                        </label>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"organization"}>
                            御社名
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <input className='input' type={"text"} name={"organization"} onChange={this.handleChange} id={"organization"} required={true} autoComplete={"organization"} placeholder={"例：株式会社○○　※法人ではない場合は個人"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"name"}>
                            お名前
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <input className='input' type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} autoComplete={"name"} placeholder={"例：山田　太郎"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"email"}>
                            メールアドレス
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <input className='input' type={"email"} name={"email"} onChange={this.handleChange} id={"email"} required={true} autoComplete={"email"} placeholder={"例：×××@×××.com"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"postal-code"}>
                            郵便番号
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <input className='input' type={"tel"} name={"postal-code"} onChange={this.handleChange} id={"postal-code"} required={true} autoComplete={"postal-code"} placeholder={"例：1030055"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"address-level1"}>
                            都道府県
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <input className='input' type={"text"} name={"address-level1"} onChange={this.handleChange} id={"address-level1"} required={true} autoComplete={"address-level1"} placeholder={"例：東京都"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"address-level2"}>
                            ご住所
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <input className='input' type={"text"} name={"address-level2"} onChange={this.handleChange} id={"address-level2"} required={true} autoComplete={"address-level2"} placeholder={"例：中央区日本橋3-3　兜町平和ビル7F"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"tel"}>
                            お電話番号
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <input className='input' type={"tel"} name={"tel"} onChange={this.handleChange} id={"tel"} required={true} autoComplete={"tel"} placeholder={"例：0301234567"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"type"}>
                            問い合わせ内容
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <label className='radio is-block-mobile'>
                                <input className='mr-1' type={"radio"} name={"type"} onChange={this.handleChange} id={"type-1"} required={true} value='当社サービスについて' />
                                当社サービスについて
                              </label>
                              <label className='radio is-block-mobile ml-0-mobile'>
                                <input className='mr-1' type={"radio"} name={"type"} onChange={this.handleChange} id={"type-2"} required={true} value='協業について' />
                                協業について
                              </label>
                              <label className='radio is-block-mobile ml-0-mobile'>
                                <input className='mr-1' type={"radio"} name={"type"} onChange={this.handleChange} id={"type-3"} required={true} value='その他' />
                                その他
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"message"}>
                            本文<span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <textarea className='textarea' name={"message"} onChange={this.handleChange} id={"message"} required={true} placeholder={"業種・目的・デザインイメージなど"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field is-horizontal'>
                        <div className='field-label is-normal'>
                          <label className='label' htmlFor={"privacy"}>
                            個人情報の
                            <br className='is-hidden-mobile' />
                            取り扱いについて
                            <br className='is-hidden-mobile' />
                            <span className='tag is-danger ml-1'>必須</span>
                          </label>
                        </div>
                        <div className='field-body'>
                          <div className='field'>
                            <div className='control'>
                              <textarea
                                className='textarea'
                                name={"message"}
                                disabled={true}
                                style={{
                                  WebkitTextFillColor: "black",
                                  opacity: "1",
                                  cursor: "text",
                                }}
                                value='プライバシーポリシー&#13;
                            株式会社V-CONNECT（以下、「当社」という。）は、取得した個人情報につき、個人情報の保護に関する法律その他関連法令を尊守し、個人情報を適切に管理するとともに、以下の通り取り扱います。&#13;
                            &#13;                            　
                            1. 定義&#13;
                            個人情報とは、当社に提供させる個人に関する情報であって、当該情報に含まれる氏名、生年月日、その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。）をいう。&#13;
                            &#13;
                            2. 個人情報の利用目的&#13;
                            皆様の個人情報は、ご記入いただいたお問い合わせへの対応のみに利用致します。&#13;
                            &#13;
                            3. 個人情報の第三者への提供&#13;
                            当社が取得した個人情報は、以下の場合を除き、ご本人の同意なく第三者に提供致しません。&#13;
                            &#13;
                            ・法令に基づき、提供に応じなければならない場合。&#13;
                            ・人の生命、身体又は財産保護のために必要である場合であって、ご本人様の同意を得ることが困難な場合。&#13;
                            ・公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご本人様の同意を得ることが困難な場合。&#13;
                            ・国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合。&#13;
                            ・合併その他の事由による事業の承継に伴って提供する場合。&#13;
                            &#13;
                            4. 個人情報の適正な管理&#13;
                            個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏洩等の予防並びに訂正に関しては、適性かつ合理的な水準での安全管理体制を維持します。個人情報へのアクセスは、必要最小限の役員及び従業員に限定します。&#13;
                            &#13;
                            5. 個人情報に関する開示等の権利、及び苦情、相談その他問い合わせ等&#13;
                            &#13;
                            1. 開示等のご請求手続き&#13;
                            当社は、ご本人から開示対象個人情報について利用目的の通知、開示、内容の訂正、追加または削除、利用停止、消去及び第三者提供の停止（以下、総称して「開示等」という。）に対応させていただきます。
                            個人情報の開示等のご請求を希望される場合は個人情報お客様相談窓口宛、お電話あるいは書面でご請求下さい。尚、利用目的の開示と通知のご請求には事務手数料として実費相当額の手数料（切手代用可）をお支払い頂く事があります。&#13;
                            ご本人であることの確認後、ご希望の個人情報の開示等のご請求に応じます。 （注）ご本人であることの確認できる書類（運転免許証、住民票、健康保険所の被保険者証のコピー）をご用意下さい。コピーは本籍地を黒く塗りつぶしてください。&#13;
                            &#13;
                            2. 代理人様による開示等のご請求&#13;
                            代理人様からのお問合せの場合、委任状や印鑑証明をもって代理人であることを確認させていただきます。&#13;
                            &#13;
                            3. 苦情及び開示等のご請求に対する回答方法&#13;
                            苦情及び開示等のご請求を頂いた方のご住所宛、書面により回答をさせていただきます。&#13;
                            &#13;
                            4. 苦情及び開示等のご請求のお問い合わせ先&#13;
                            ＜個人情報お客様相談窓口＞&#13;
                            株式会社V-CONNECT&#13;
                            個人情報保護管理者：代表取締役	中島　和賢&#13;
                            〒103-0026 東京都中央区日本橋兜町3-3兜町平和ビル7F&#13;
                            TEL：03-6260-6550（平日10時～19時）&#13;
                            &#13;
                            6. 提供の任意性&#13;
                            皆様の個人情報のご提供は、ご本人の自由なご判断に任されます。ただし、個人情報の一部をご提供いただけない場合、お問い合わせに適切に対応できないことがあります。'
                              />
                              <label className='checkbox'>
                                <input className='mr-1' type={"checkbox"} name={"privacy"} onChange={this.handleChange} id={"privacy"} required={true} />
                                個人情報の取扱について同意する
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='field mt-5'>
                        <div className='control'>
                          <div className='columns is-centered'>
                            <div className='column is-half'>
                              <button className='button is-link is-fullwidth' type='submit'>
                                送信
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        )}
      />
    );
  }
}
